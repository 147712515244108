@import '../../../sass/variables/colors';
@import '../../../sass/variables/sizes';

.footer {
  background-color: $red;

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 0;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  small {
    color: $white;
    opacity: 0.6;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__logo {
    margin: 27px 0;

    @media screen and (max-width: $sm - 1) {
      margin: 27px auto;
      display: flex;
      justify-content: center;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;

    small {
      order: 1;
    }

    @media screen and (max-width: $sm - 1) {
      flex-direction: column;
      align-items: center;

      small {
        order: 2;
        margin-top: 15px;
      }

      &_social {
        order: 1 !important;
        width: 100%;
        justify-content: space-around;
      }
    }

    &_social {
      display: flex;
      flex-direction: row;
      order: 2;

      a {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        > div {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 50%;

          > div > div {
            display: flex;
          }

          svg {
            width: 18px;
            height: 18px;
            color: $white;
          }
        }
      }
    }
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 5vw;

    @media (max-width: $md) {
      flex-direction: column;
      max-height: 380px;
      flex-wrap: wrap;
      padding: 0;
    }

    @media screen and (max-width: $sm - 1) {
      max-height: none;
    }

    @media screen and (max-width: $xs) {
      align-content: space-between;
    }
  }

  &__menu {
    margin: 15px 0;
    font-size: 14px;

    h5 {
      color: $white;
      opacity: 0.6;
      font-weight: 600;
      margin: 12px 0;
      font-size: 14px;
      text-transform: uppercase;
    }

    p {
      color: $white;
      margin: 0;
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &--showXS {
      display: none;

      @media (max-width: $xs) {
        display: block;
      }
    }

    &--hideXS {
      display: block;

      @media (max-width: $xs) {
        display: none;
      }
    }
  }
}
