@import '../variables/sizes';

// unitless function to convert px to em
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@mixin breakpoint($mq01: 0, $mq2: false, $maxMin: max-width) {
  @if $mq2 == false {
    @media ($maxMin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}

@mixin font-size-map($font-size-map) {
  @each $breakpoint, $font-size in $font-size-map {
    @if $breakpoint == null {
      font-size: em($font-size);
    } @else {
      @include breakpoint($breakpoint) {
        font-size: em($font-size);
      }
    }
  }
}
