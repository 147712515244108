@import './mixins/mixins';
@import './variables/sizes';
@import './variables/colors';
@import './variables/fonts';
@import './layout/base';

body {
  margin: 0;
  background: $white;
}

html,
body,
form,
fieldset,
table,
tr,
td,
img {
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

// font sizes maps
html {
  font-size: #{$browser-context}px;
  color: $black;
}

h1 {
  @include font-size-map($h1-font-size);
}

h3,
h2 {
  @include font-size-map($h3-font-size);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
