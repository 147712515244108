@import '../variables/sizes';
@import '../mixins/mixins';
@import '../variables/colors';

.container {
  padding: 0 10vw;

  &--fluid {
    padding: 0 15px;
  }
}

$h1-terms: (
  null: 58,
  $md: 52,
  $sm: 46,
  $xs: 40,
);
$h3-terms: (
  null: 36,
  $md: 32,
  $sm: 28,
  $xs: 24,
);

.terms-page {
  padding-top: 15px;
  padding-bottom: 15px;

  section {
    padding: 10px 0;
  }

  h1 {
    @include font-size-map($h1-terms);
    margin: 30px 0;
  }

  h3 {
    @include font-size-map($h3-terms);
    margin: 18px 0;
  }

  p {
    color: $lightGray;
    line-height: em(28);
    font-size: em(18);
    margin: 15px 0;

    @media screen and (max-width: $sm) {
      line-height: em(24);
      font-size: em(16);
    }
  }

  a {
    color: $black;
  }

  strong {
    color: $black;
  }
}

.redirect-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  button {
    background-color: $red;
    border: none;
    border-radius: 10px;
    padding: 20px 40px;
    color: $white;
    font-size: em(14);
    cursor: pointer;
    margin-top: 30px;
  }

  p {
    margin: 5px 0;
  }
}
